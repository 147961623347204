






































































































































































































































































































































































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import { mask } from 'vue-the-mask'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import { namespace } from 'vuex-class'
import cloneDeep from 'lodash/cloneDeep'

import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import NotificationMixin from '@/mixins/notificationMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import ServicesFormMixin from '@/mixins/serviceFormMixin'
import MunicipalitiesComboBox from '@/partials/forms/Services/components/MunicipalitiesComboBox.vue'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import ProfileUploader from '@/partials/components/ProfileUploader.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import DateInput from '@/partials/forms/components/DateTime/DateSimple.vue'

const sessionModule = namespace('session')

@Component({
  directives: { mask },
  components: {
    ValidationProvider,
    ValidationObserver,
    MunicipalitiesComboBox,
    NotifyOnFailed,
    ProfileUploader,
    PasswordConfirmField,
    DateInput
  }
})
export default class ServicesForm extends Mixins(NotificationMixin, FlashMessageMixin, ServicesFormMixin) {
  @Prop({ default: 'Serviço' }) title!: string
  @Prop() serviceId!: string
  @Prop({ default: '' }) vistoryToken!: string
  @Prop({ default: false, type: Boolean }) publicCreate!: boolean
  vistoryDone = false
  showVacancies = false

  @sessionModule.State(state => state) sessionState!: Record<string, any>

  windowWidth = window.innerWidth
  ufs = []
  regionals = []
  municipalities = []

  done = true

  form: Record<string, any> = {
    id: '',
    name: '',
    businessName: '',
    cnpj: '',
    certifiedAt: '',
    address: '',
    cep: '',
    ufId: '',
    municipalityId: '',
    phone: '',
    email: '',
    site: '',
    regionalId: '',
    status: 'active',
    notes: '',
    sus: true,
    trainingDuration: 1,
    vacancies: 1,
    picture: '',
    confirm: '',
    profile: {
      type: 'type',
      complexity: 'simple',
      weeklyAssistance: [],
      ambulatory: [],
      extraRoom: {},
      complementaryExams: {},
      specialEquipments: {},
      teachingInfrastructure: {},
      weeklyDidacticActivities: {},
      assistentialActivities: {},
      researchAndScientificEventActivities3YearAverage: {},
      yearlySurgicalProceduresPerYear: {}
    },
    errors: {
      name: '',
      businessName: '',
      cnpj: '',
      address: '',
      cep: '',
      ufId: '',
      municipalityId: '',
      phone: '',
      email: '',
      site: '',
      regionalId: '',
      status: '',
      notes: '',
      sus: '',
      trainingDuration: '',
      confirm: '',
      'profile.type': '',
      'profile.complexity': '',
      'profile.weeklyAssistance': '',
      'profile.ambulatory': '',
      'profile.extraRoom': '',
      'profile.complementaryExams': '',
      'profile.specialEquipments': '',
      'profile.teachingInfrastructure': '',
      'profile.weeklyDidacticActivities': '',
      'profile.assistentialActivities': '',
      'profile.researchAndScientificEventActivities3YearAverage': '',
      'profile.yearlySurgicalProceduresPerYear': ''
    }
  }

  servicePictureURL = ''
  pictureId = ''

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }

  onResize () {
    this.windowWidth = window.innerWidth
  }

  validations () {
    extend('required', {
      ...required,
      message: 'The {_field_} field is required.'
    })

    extend('email', {
      ...email,
      message: 'The email must be a valid email address.'
    })
  }

  hasCreatedFinished = false
  async created () {
    this.validations()

    if (process.env.NODE_ENV === 'development') {
      Object.assign(this.form, {
        id: '',
        name: 'Serviço Teste',
        businessName: 'Serviços Saúde Avançada Teste LTDA',
        cnpj: '09.876.543/0001-00',
        address: 'Endereço',
        cep: '00000-000',
        ufId: '53',
        municipalityId: '5300108',
        phone: '(61) 3456-7890',
        email: 'centroservico@indicativa.com.br',
        site: 'https://sbq.indicativa.com.br',
        regionalId: '',
        notes: 'Development',
        status: 'active',
        sus: true,
        trainingDuration: 1,
        vacancies: 1,
        confirm: '',
        profile: {
          type: 'type',
          complexity: 'simple',
          weeklyAssistance: [],
          ambulatory: [],
          extraRoom: {},
          complementaryExams: {},
          specialEquipments: {},
          teachingInfrastructure: {},
          weeklyDidacticActivities: {},
          assistentialActivities: {},
          researchAndScientificEventActivities3YearAverage: {},
          yearlySurgicalProceduresPerYear: {}
        }
      })
    }

    this.extraRoom.forEach(room => {
      this.form.profile.extraRoom[room.key] = 0
    })

    this.complementaryExams.forEach(exam => {
      this.form.profile.complementaryExams[exam.key] = 0
    })

    this.specialEquipments.forEach(equipment => {
      this.form.profile.specialEquipments[equipment.key] = 0
    })

    this.teachingInfrastructure.forEach(infra => {
      this.form.profile.teachingInfrastructure[infra.key] = 0
    })

    this.weeklyDidacticActivities.forEach(weekly => {
      this.form.profile.weeklyDidacticActivities[weekly.key] = 0
    })

    this.assistentialActivities.forEach(assistential => {
      this.form.profile.assistentialActivities[assistential.key] = 0
    })

    this.researchAndScientificEventActivities3YearAverage.forEach(research => {
      this.form.profile.researchAndScientificEventActivities3YearAverage[research.key] = 0
    })

    this.yearlySurgicalProceduresPerYear.forEach(procedures => {
      this.form.profile.yearlySurgicalProceduresPerYear[procedures.key] = 0
    })

    await axios.get('geo/br/states')
      .then((response) => {
        this.ufs = response.data.data
      })

    await axios.get('services/regionals')
      .then((response) => {
        this.regionals = response.data.data
      })

    let municipalityId = ''

    if (this.serviceId || this.vistoryToken) {
      const route = this.serviceId ? `service/${this.serviceId}` : `service/vistory/${this.vistoryToken}`
      axios.get(route)
        .then((response) => {
          const service = camelCaseKeys(response.data.data, { deep: true })
          municipalityId = service.municipalityId
          Object.keys(service).forEach((key: string) => {
            if (key !== 'profile') {
              this.form[key] = key in this.form ? service[key] : this.form[key]
            } else {
              const profile: Record<string, any> = {}
              service.profile.forEach((item: ProfileItem) => {
                profile[item.metaKey] = JSON.parse(item.metaValue)
              })
              this.form.profile = camelCaseKeys(profile, { deep: true })
              if (this.form.notes === 'pending-initial-vistory') {
                this.form.notes = ''
              }

              this.showVacancies = !!this.vistoryToken
            }
          })
          if (service.picture) {
            this.servicePictureURL = service.picture.publicPath
            this.pictureId = service.picture.id
          }
        })
        .then(async () => {
          this.hasCreatedFinished = true
          await this.fetchMunicipalities(this.form.ufId)
          this.form.municipalityId = municipalityId
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      this.hasCreatedFinished = true
    }
  }

  @Watch('form.ufId')
  fetchMunicipalities (newValue: string) {
    if (this.hasCreatedFinished) {
      return axios.get(`geo/br/municipalities/${newValue}`)
        .then((response) => {
          this.municipalities = response.data.data
          this.form.municipalityId = ''
        })
    }
  }

  submitForm () {
    this.done = false

    const verb = this.serviceId || this.vistoryToken ? 'put' : 'post'
    let route = 'services'
    let successVerb = 'cadastrado'

    if (this.serviceId) {
      route = `service/${this.serviceId}`
      successVerb = 'editado'
    }

    if (this.vistoryToken) {
      route = `service/${this.form.id}/vistory/${this.vistoryToken}`
      successVerb = 'editado'
    }

    if (this.publicCreate) {
      route = '/public/services'
      successVerb = 'cadastrado'
      this.form.status = 'visit'
      this.form.notes = ''
    }

    let requestForm = Object.assign({}, this.form)
    requestForm.profile = []

    delete requestForm.errors
    delete requestForm.role

    Object.entries(snakeCaseKeys(this.form.profile)).forEach(([key, value]) => {
      requestForm.profile.push({ key: key, value: JSON.stringify(value) })
    })

    requestForm = snakeCaseKeys(requestForm)

    axios[verb](route, requestForm)
      .then(async (response) => {
        if (this.vistoryToken) {
          this.vistoryDone = true
        }

        if (this.publicCreate) {
          await this.associateChiefToService(response.data.data.id)
          await this.removeChiefMetaKey()
        }

        this.redirectOnSuccess(successVerb, this.form.name, verb)

        this.setNotification('success', 'Obrigado.')
      })
      .catch(err => {
        const response = err.response
        const errors = response.data.errors
        if (errors) {
          this.setNotification('error', 'Os dados fornecidos não são válidos, verifique o formulário.')
          if (response.data.errors) {
            const errors = camelCaseKeys(response.data.errors)
            console.log(errors)

            Object.entries(errors).map(([key, value]: [string, any]) => {
              this.form.errors[key] = value[0]
            })
          }
        } else {
          this.setNotification('error', response.data.error)
        }
      })
      .finally(() => { this.done = true })
  }

  associateChiefToService (serviceId: string) {
    return axios.patch(`public/service/${serviceId}/chief`)
  }

  removeChiefMetaKey () {
    const submitForm = cloneDeep(this.sessionState.user)
    const propertyIndex = submitForm.profile.findIndex((profile: any) => profile.metaKey === 'isChiefWithoutService')

    submitForm.profile.splice(propertyIndex)

    submitForm.profile = submitForm.profile.map((profile: any) => {
      return { key: profile.metaKey, value: profile.metaValue }
    })

    submitForm.confirm = this.form.confirm

    return axios.put(`user/${submitForm.id}/profile`, snakeCaseKeys(submitForm))
  }

  redirectOnSuccess (successVerb: string, serviceName: string, requestVerb: string) {
    const flashMessage = {
      message: `O serviço ${serviceName} foi ${successVerb} com sucesso.`,
      isSuccess: true
    }

    if (requestVerb === 'post') {
      this.$router.push({ name: 'Services' }, () => this.setFlashMessage(flashMessage))
    } else if (requestVerb === 'put') {
      if (!this.vistoryToken) {
        this.$router.push({ name: 'ServicesShow', params: { id: this.serviceId } }, () => this.setFlashMessage(flashMessage))
      }
    }
  }

  resetErrors () {
    this.form.errors = {
      name: '',
      businessName: '',
      cnpj: '',
      address: '',
      cep: '',
      ufId: '',
      municipalityId: '',
      phone: '',
      email: '',
      site: '',
      regionalId: '',
      status: '',
      sus: '',
      trainingDuration: '',
      vacancies: '',
      'profile.type': '',
      'profile.complexity': '',
      'profile.weeklyAssistance': '',
      'profile.ambulatory': '',
      'profile.extraRoom': '',
      'profile.complementaryExams': '',
      'profile.specialEquipments': '',
      'profile.teachingInfrastructure': '',
      'profile.weeklyDidacticActivities': '',
      'profile.assistentialActivities': '',
      'profile.researchAndScientificEventActivities3YearAverage': '',
      'profile.yearlySurgicalProceduresPerYear': '',
      confirm: ''
    }
  }
}
