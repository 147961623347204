import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ServiceFormMixin extends Vue {
  statuses = [
    {
      name: 'active',
      prettyName: 'Ativo'
    },
    {
      name: 'inactive',
      prettyName: 'Inativo'
    },
    {
      name: 'moratorium',
      prettyName: 'Moratória'
    },
    {
      name: 'visit',
      prettyName: 'Vistoria'
    },
    {
      name: 'demo',
      prettyName: 'Demonstração'
    }
  ]

  types = [
    'Hospital Universitário',
    'Hospital de Ensino',
    'Hospital com Internato',
    'Hospital Geral',
    'Hospital Privado',
    'Hospital Público Federal',
    'Hospital Público Estadual',
    'Hospital Público Municipal',
    'Hospital Filantrópico'
  ]

  complexities = [
    {
      name: 'Simples',
      value: 'simple'
    },
    {
      name: 'Média',
      value: 'medium'
    },
    {
      name: 'Complexa',
      value: 'complex'
    }
  ]

  weeklyAssistance = [
    'Procedimentos Cirúrgicos',
    'Atendimentos Ambulatoriais',
    'Atendimentos de Pronto-Socorro',
    'Internações'
  ]

  ambulatory = [
    'Ambulatório de Quadril',
    'Ambulatório de Oncologia Ortopédica',
    'Ambulatório de Trauma',
    'Ambulatório de Traumatologia do Esporte',
    'Ambulatório de Fisiatria',
    'Ambulatório de Reumatologia',
    'Ambulatório de Neurologia',
    'Medicina Hiperbárica',
    'Geriatria',
    'Fisioterapia',
    'Cadastramento para Transplante de Tecidos'
  ]

  extraRoom = [
    {
      label: 'Consultórios disponíveis',
      key: 'consultoriosDisponiveis'
    },
    {
      label: 'Salas para Pequenos Procedimentos',
      key: 'salasParaPequenosProcedimentos'
    },
    {
      label: 'Postos de Enfermagem Anexo',
      key: 'postosDeEnfermagemAnexo'
    },
    {
      label: 'Salas de Urgência',
      key: 'salasDeUrgencia'
    },
    {
      label: 'Leitos de Terapia Intensiva',
      key: 'leitosDeTerapiaIntensiva'
    },
    {
      label: 'Salas em Centro Cirúrgico',
      key: 'salasEmCentroCirurgico'
    },
    {
      label: 'Comissão de Controle de Infecção Hospital (CCIH)',
      key: 'comissaoDeControleDeInfeccaoHospital'
    },
    {
      label: 'Salas de Imobilização',
      key: 'salasDeImobilizacao'
    },
    {
      label: 'Técnicos de Imobilização',
      key: 'tecnicosDeImobilizacao'
    },
    {
      label: 'Leitos de Enfermaria',
      key: 'leitosDeEnfermaria'
    },
    {
      label: 'Leitos de Pronto-Socorro',
      key: 'leitosDeProntoSocorro'
    }
  ]

  complementaryExams = [
    {
      label: 'Anatomia Patológica',
      key: 'anatomiaPatologica'
    },
    {
      label: 'Radiografia',
      key: 'radiografia'
    },
    {
      label: 'Ultrassonografia',
      key: 'ultrassonografia'
    },
    {
      label: 'Tomografia Computadorizada',
      key: 'tomografiaComputadorizada'
    },
    {
      label: 'Ressonância Magnética',
      key: 'ressonanciaMagnetica'
    },
    {
      label: 'Densitometria Óssea',
      key: 'densitometriaOssea'
    },
    {
      label: 'Laboratório de Análises Clínicas',
      key: 'laboratorioDeAnalisesClinicas'
    },
    {
      label: 'Medicina Nuclear',
      key: 'medicinaNuclear'
    },
    {
      label: 'Laboratório de Marcha',
      key: 'laboratorioDeMarcha'
    },
    {
      label: 'Eletroneuromiografia',
      key: 'eletroneuromiografia'
    }
  ]

  specialEquipments = [
    {
      label: 'Videoartroscopia',
      key: 'videoartroscopia'
    },
    {
      label: 'Navegador Cirúrgico',
      key: 'navegadorCirurgico'
    },
    {
      label: 'Mesa Ortopédica',
      key: 'mesaOrtopedica'
    },
    {
      label: 'Intensificador de Imagem',
      key: 'intensificadorDeImagem'
    }
  ]

  teachingInfrastructure = [
    {
      label: 'Acesso à Internet',
      key: 'acessoAInternet'
    },
    {
      label: 'Auditório',
      key: 'auditorio'
    },
    {
      label: 'Sala de Reuniões',
      key: 'salaDeReunioes'
    },
    {
      label: 'Sala para os Especializandos',
      key: 'salaParaOsEspecializandos'
    },
    {
      label: 'Multimídia',
      key: 'multimidia'
    },
    {
      label: 'Secretaria de Preceptoria',
      key: 'secretariaDePreceptoria'
    },
    {
      label: 'Comitê de Ética em Pesquisa (CEP)',
      key: 'comiteDeEticaEmPesquisa'
    },
    {
      label: 'Comissão Científica',
      key: 'comissaoCientifica'
    },
    {
      label: 'Laboratório de Artroscopia',
      key: 'laboratorioDeArtroscopia'
    },
    {
      label: 'Laboratório de Habilidades',
      key: 'laboratorioDeHabilidades'
    },
    {
      label: 'Laboratório de Cirurgia Experimental',
      key: 'laboratorioDeCirurgiaExperimental'
    },
    {
      label: 'Laboratório de Biomecânica',
      key: 'laboratorioDeBiomecanica'
    },
    {
      label: 'Laboratório de Anatomia',
      key: 'laboratorioDeAnatomia'
    },
    {
      label: 'Biblioteca',
      key: 'biblioteca'
    },
    {
      label: 'Bibliografia CEC Atualizada',
      key: 'bibliografiaCecAtualizada'
    },
    {
      label: 'Número de Periódicos Internacionais',
      key: 'numeroDePeriodicosInternacionais'
    },
    {
      label: 'Número de Periódicos Nacionais Indexados',
      key: 'numeroDePeriodicosNacionaisIndexados'
    },
    {
      label: 'Número de Eventos Científicos Anuais Promovidos com Público Externo',
      key: 'numeroDeEventosCientificosAnuaisPromovidosComPublicoExterno'
    }
  ]

  weeklyDidacticActivities = [
    {
      label: 'Discussão de artigos',
      key: 'discussaoDeArtigos'
    },
    {
      label: 'Discussão de casos',
      key: 'discussaoDeCasos'
    },
    {
      label: 'Seminários',
      key: 'seminariosSemanais'
    },
    {
      label: 'Aula dos preceptores',
      key: 'aulaDosPreceptores'
    },
    {
      label: 'Aula dos especializandos',
      key: 'aulaDosEspecializandos'
    },
    {
      label: 'Visita à enfermaria',
      key: 'visitaEnfermaria'
    },
    {
      label: 'Reunião clínica geral',
      key: 'reuniaoClinicaGeral'
    },
    {
      label: 'Reunião com especializandos',
      key: 'reuniaoComEspecializandos'
    }
  ]

  assistentialActivities = [
    {
      label: 'Pacientes ambulatoriais por residente por dia',
      key: 'pacientesAmbulatoriaisResidentesDia'
    },
    {
      label: 'Operações por residentes por mês (média)',
      key: 'operacoesResidentesMesMedia'
    }
  ]

  researchAndScientificEventActivities3YearAverage = [
    {
      label: 'Tema livre por especializando',
      key: 'temaLivreEspecializando'
    },
    {
      label: 'Trabalho científico publicado por especializando em revista indexada',
      key: 'trabalhoCientificoEspecializandoRevistaIndexada'
    },
    {
      label: 'Participação em eventos científicos',
      key: 'participacaoEventosCientificos'
    }
  ]

  yearlySurgicalProceduresPerYear = [
    {
      label: 'Artroplastia Primária de Quadril',
      key: 'artroplastiaPrimariaQuadril'
    },
    {
      label: 'Revisão de Artroplastia de Quadril',
      key: 'revisaoArtroplastiaQuadril'
    },
    {
      label: 'Fratura Transtrocantérica',
      key: 'fraturaTranstrocanterica'
    },
    {
      label: 'Fratura do Colo do Fêmur',
      key: 'fraturaColoFemur'
    },
    {
      label: 'Fratura Suntrocantérica',
      key: 'fraturaSuntrocanterica'
    },
    {
      label: 'Fratura do Acetábulo',
      key: 'fraturaAcetabulo'
    },
    {
      label: 'Fratura da Bacia',
      key: 'fraturaBacia'
    },
    {
      label: 'Artroscopia',
      key: 'artroscopia'
    },
    {
      label: 'Cirurgia Preservadora do Quadril Aberta',
      key: 'cirurgiaPreservadoraQuadrilAberta'
    }
  ]
}
